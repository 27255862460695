<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit DecisionLicense')"
        >
          <b-row>
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md=""
                  class="d-flex justify-content-center"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex justify-content-center"
                    >
                      <upload
                        v-model="data.img"
                        @on-file-error="UpdateFileError"
                      />
                    </b-col>
                    <b-col cols="12"><h2 class="text-center">
                                       img
                                     </h2>
                      <b-alert
                        v-if="fileErrors.length>0"
                        variant="danger"
                        show
                      >
                        <h4 class="alert-heading">
                          {{ $t('Upload Image Error') }}
                        </h4>
                        <div class="alert-body">
                          <span>
                            <ul
                              v-for="(val,index) in fileErrors"
                              :key="index"
                            >
                              <li>{{ val }}</li>
                            </ul>
                          </span>
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md=""
                  class="d-flex justify-content-center"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex justify-content-center"
                    >
                      <upload
                        v-model="data.simg_id"
                        @on-file-error="UpdateFileError"
                      />
                    </b-col>
                    <b-col cols="12"><h2 class="text-center">
                                       simg
                                     </h2>
                      <b-alert
                        v-if="fileErrors.length>0"
                        variant="danger"
                        show
                      >
                        <h4 class="alert-heading">
                          {{ $t('Upload Image Error') }}
                        </h4>
                        <div class="alert-body">
                          <span>
                            <ul
                              v-for="(val,index) in fileErrors"
                              :key="index"
                            >
                              <li>{{ val }}</li>
                            </ul>
                          </span>
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.title"
                  class="mt-2"
                  :state="errors.title ?false:null"
                  :placeholder="$t('title')"
                />
                <small
                  v-if="errors.title"
                  class="text-danger"
                >{{ errors.title[0] }}</small>
                <label>{{ $t('title') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('Available/Unavailable') }}</label>
                <b-form-checkbox
                  v-model="data.status"
                  :value="true"
                  :state="errors.status ?false:null"
                >
                  {{ data.status ? $t('Available') : $t('Unavailable') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.status"
                class="text-danger"
              >{{ errors.status[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('body') }}</label>
                <b-form-textarea
                  v-model="data.body"
                  class="mt-2"
                  :state="errors.body ?false:null"
                  :placeholder="$t('body')"
                />
                <small
                  v-if="errors.body"
                  class="text-danger"
                >{{ errors.body[0] }}</small>
                <label>{{ $t('body') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('sbody') }}</label>
                <b-form-textarea
                  v-model="data.sbody"
                  class="mt-2"
                  :state="errors.sbody ?false:null"
                  :placeholder="$t('sbody')"
                />
                <small
                  v-if="errors.sbody"
                  class="text-danger"
                >{{ errors.sbody[0] }}</small>
                <label>{{ $t('sbody') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['decisionLicense/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('decisionLicense/GetElement', this.$route.params.decisionLicense).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.data.part = 'main'
      this.errors = []
      this.$store.dispatch('decisionLicense/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
